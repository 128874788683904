import getData from "@/__main__/get-data.mjs";
import type { RouteState } from "@/__main__/router.mjs";
import { TOTAL_SEASONS_DATA } from "@/game-destiny2/constants/season.mjs";
import { populateMatchHistory } from "@/game-destiny2/fetches/bungie-activities.mjs";
import * as bClient from "@/game-destiny2/fetches/bungie-client.mjs";
import type { Membership } from "@/game-destiny2/models/destiny2-bungie-profile.mjs";
import { BungieProfileValidator } from "@/game-destiny2/models/destiny2-bungie-profile.mjs";
import { MatchValidator } from "@/game-destiny2/models/destiny2-match.mjs";
import { MatchListValidator } from "@/game-destiny2/models/destiny2-match-list.mjs";
import { GuardianStatsValidate } from "@/game-destiny2/models/destiny2-player-guardian-stats.mjs";
import { GuardianMapStatsValidate } from "@/game-destiny2/models/destiny2-player-map-stats.mjs";
import { GuardianWeaponStatsValidate } from "@/game-destiny2/models/destiny2-player-weapon-stats.mjs";
import { SeasonValidate } from "@/game-destiny2/models/destiny2-seasons.mjs";
import * as API from "@/game-destiny2/utils/api.mjs";
import { devError } from "@/util/dev.mjs";

export default async function fetchData(
  [bungieId, tab]: [string, string],
  _searchParams: URLSearchParams,
  state: RouteState,
) {
  if (!bungieId) return;

  try {
    populateMatchHistory(bungieId); // TODO: clean up & grab in progress/ new ones from bungie
    const isSync = !!state?.isSync;
    const requiredPromises = [];
    const sideLoadPromises = [];

    // TODO: add fallback to bungie
    const [profile, seasons] = await Promise.all([
      getData(API.getProfile({ bungieId }), BungieProfileValidator, [
        "destiny2",
        "profiles",
        bungieId,
      ]),
      getData(API.getSeasons(), SeasonValidate, ["destiny2", "seasons"]),
    ] as const);

    // TODO: may need to aggregate character ids if cross save is being weird
    // That is a whole other can of worms tho esp w/ tokens and if they are for one membership only
    const guardianIds: string[] = profile.guardians.map((g) => g.characterId);
    const seasonIds =
      seasons?.slice(0, TOTAL_SEASONS_DATA).map((s) => s.id) ?? [];

    const isBlitzUser = profile.blitzUser;
    const characterLoadoutPromise = fetchCharacterLoadout(
      bungieId,
      profile.memberships,
      isSync,
    );
    // TODO: i assume they want to disable map aggs for non blitz users?
    const mapAggregationsPromise = Promise.all(
      guardianIds.map((cId) => fetchMapAggregatesForCharacter(cId, seasonIds)),
    );
    const weaponAggregationsPromise =
      isBlitzUser &&
      Promise.all(
        guardianIds.map((cId) =>
          fetchWeaponAggregatesForCharacter(cId, seasonIds),
        ),
      );
    const guardianAggregationsPromise = Promise.all(
      guardianIds.map((cId) =>
        fetchGuardianAggregatesForCharacter(cId, seasonIds),
      ),
    );

    sideLoadPromises.push(
      characterLoadoutPromise,
      mapAggregationsPromise,
      weaponAggregationsPromise,
      guardianAggregationsPromise,
    );
    switch (tab) {
      case "guardians": {
        requiredPromises.push(
          characterLoadoutPromise,
          guardianAggregationsPromise,
        );
        break;
      }
      case "weapons": {
        requiredPromises.push(weaponAggregationsPromise);
        break;
      }
      case "maps": {
        requiredPromises.push(mapAggregationsPromise);
        break;
      }
      case "overview":
      default: {
        requiredPromises.push(
          ...seasonIds.map((seasonId) =>
            getData(
              API.getMatchList({ bungieId, seasonId }),
              MatchListValidator,
              ["destiny2", "matchList", bungieId],
              { mergeFn: API.matchListMerge },
            ),
          ),
        );
      }
    }

    const visibleMatches = Object.entries(
      state.transient?.visibleMatches ?? {},
    ).filter(([_, isVisible]) => isVisible);
    if (visibleMatches)
      requiredPromises.push(
        ...visibleMatches.map(([seasonActivityId]) => {
          const [seasonId, activityId] = seasonActivityId.split("-");
          return getData(
            API.getMatch({ activityId, seasonId }),
            MatchValidator,
            ["destiny2", "match", activityId],
          );
        }),
      );

    Promise.all(sideLoadPromises);

    return Promise.all(requiredPromises);
  } catch (e) {
    devError("Failed to fetch profile page data", e);
  }
}

const fetchCharacterLoadout = async (
  bungieId: string,
  memberships: readonly Membership[],
  forceFetch: boolean,
) => {
  await Promise.all(
    memberships.map((membership) =>
      bClient.getCharacterLoadouts(bungieId, membership, {
        networkBackOffTime: bClient.CHARACTER_LOADOUT_BACKOFF,
        shouldFetchIfPathExists: forceFetch,
        shouldSkipDBRead: true,
      }),
    ),
  );
};

const AGG_BACKOFF_TIME = 10 * 60 * 1000;

// Get last season in range that character has stats for
const fetchMapAggregatesForCharacter = async (
  characterId: string,
  seasonIds: string[],
) => {
  for (const seasonId of seasonIds) {
    const stats = await getData(
      API.getPlayerMapStats({ characterId, seasonId }),
      GuardianMapStatsValidate,
      ["destiny2", "mapStats", characterId],
      { networkBackOffTime: AGG_BACKOFF_TIME },
    );
    if (stats.length) break;
  }
};

const fetchWeaponAggregatesForCharacter = async (
  characterId: string,
  seasonIds: string[],
) => {
  for (const seasonId of seasonIds) {
    const stats = await getData(
      API.getPlayerWeaponStats({ characterId, seasonId }),
      GuardianWeaponStatsValidate,
      ["destiny2", "weaponStats", characterId],
      { skipSafetyCheck: true },
    );
    if (stats.length) break;
  }
};

const fetchGuardianAggregatesForCharacter = async (
  characterId: string,
  seasonIds: string[],
) => {
  await Promise.all(
    seasonIds.map((sId) =>
      getData(
        API.getPlayerGuardianStats({ characterId, seasonId: sId }),
        GuardianStatsValidate,
        ["destiny2", "guardianStats", characterId, sId],
        { skipSafetyCheck: true },
      ),
    ),
  );
};
