import { readState } from "@/__main__/app-state.mjs";
import getData from "@/__main__/get-data.mjs";
import type { MEMBERSHIP_TYPE } from "@/game-destiny2/constants/membership-types.mjs";
import type { ProfileLoadout } from "@/game-destiny2/models/bungie-character-loadout.mjs";
import { BungieGetCharacterLoadout } from "@/game-destiny2/models/bungie-character-loadout.mjs";
import * as API from "@/game-destiny2/utils/api.mjs";
import { extractBungieApiError } from "@/game-destiny2/utils/bungie-api-error.mjs";
import { getBungieApiHeaders } from "@/game-destiny2/utils/get-destiny2-header.mjs";
import { devError } from "@/util/dev.mjs";

type Opts = Partial<{
  networkBackOffTime: number;
  shouldFetchIfPathExists: boolean;
  shouldSkipDBRead: boolean;
}>;

const isBungieApiDown = () => readState.volatile.d2ApiUnavailable === true;

export const CHARACTER_LOADOUT_BACKOFF = 30 * 1000;
export async function getCharacterLoadouts(
  bungieId: string,
  membership: {
    membershipId: string;
    membershipType: MEMBERSHIP_TYPE;
  },
  opts: Opts = {
    networkBackOffTime: CHARACTER_LOADOUT_BACKOFF,
    shouldSkipDBRead: true,
  },
) {
  if (isBungieApiDown()) return null;
  try {
    return await getData(
      API.getCharacterLoadout(membership),
      BungieGetCharacterLoadout,
      ["destiny2", "characterLoadout", bungieId],
      {
        headers: await getBungieApiHeaders(bungieId),
        mergeFn: mergeLoadouts,
        ...opts,
      },
    );
  } catch (e) {
    const apiError = extractBungieApiError(e);
    if (!apiError) throw e;

    // TODO: better handle known error types
    devError(apiError.message, apiError.errorType, apiError);
    return apiError;
  }
}

// BC of cross save we can end up in wacky places where a character is only on one membership
// Needs a lot more testing
const mergeLoadouts = (
  a: ProfileLoadout,
  b: ProfileLoadout,
): ProfileLoadout => {
  if (a instanceof Error || !a) return b;
  if (b instanceof Error || !b) return a;

  return {
    ...b,
    characters: { ...a.characters, ...b.characters },
  };
};
